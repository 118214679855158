import axios from '../axios'
//
// 预定机票
export const ticket_api = (data) => {
    return axios({
        method: "post", // 请求方式
        url: "/ticket/add", // 请求地址
        data: data,
    });
};

