<template>
  <div class="tab">
    <div class="head_img_box">
      <img class="head_img" src="../assets/images/ban.png" alt="飞机" />
    </div>
    <div class="tab_box">
      <van-tabs v-model="active">
        <van-tab title="单程">
          <div class="one_way">
            <!-- 选择起始地址 -->
            <div class="check_address">
              <!-- <div
                :class="this.startAddress==false?'start_address none_address':'start_address'"
                v-text="this.startAddress==false?'出发地':this.startAddress"
              ></div>-->
              <input
                style="border:0;width:100px;font-size:18px"
                type="text"
                placeholder="出发地"
                maxlength="5"
                v-model="user.leave"
              />
              <div class="icon">
                <img src="@/assets/images/fj.png" alt />
              </div>
              <!-- <div
                :class="this.flagAddress==false?'flag_address none_address':'flag_address'"
                v-text="this.flagAddress==false?'目的地':this.flagAddress"
              ></div>-->
              <input
                style="border:0;width:100px;font-size:18px"
                type="text"
                placeholder="目的地"
                maxlength="5"
                v-model="user.destination"
              />
            </div>
            <van-divider />
            <!-- 选择时间 -->
            <div class="check_time">
              <div class="time_box">
                <!-- <p class="time">10月30日</p>
                <p class="day">今天 出发</p>-->
                <van-field
                  readonly
                  clickable
                  name="datetimePicker"
                  :value="user.value2"
                  placeholder="出发日期"
                  @click="showPicker = true"
                />
                <van-popup v-model="showPicker" position="bottom">
                  <!-- <van-datetime-picker
                    v-model="currentDate"
                    type="datetime"
                    title="请选择出发日期"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @confirm="onchange"
                    @cancel="showPicker=false"
                  />-->
                  <van-datetime-picker
                    v-model="currentDate"
                    type="month-day"
                    title="选择月日"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :formatter="formatter"
                      @confirm="onchange"
                    @cancel="showPicker=false"
                  />
                </van-popup>
              </div>
              <van-icon name="arrow" />
            </div>
            <van-divider />
            <!-- 仓位以及是否携带幼儿 -->
            <div class="check_space_children">
              <div class="space">
                <van-dropdown-menu active-color="#1989fa">
                  <van-dropdown-item v-model="value1" :options="option1" />
                </van-dropdown-menu>
                <!-- <van-icon name="arrow" /> -->
              </div>
              <div class="children">
                <van-checkbox-group v-model="result" icon-size='12px' direction="horizontal">
                  <van-checkbox name="1">携带儿童<div class="age">2-12岁</div></van-checkbox>
                  <van-checkbox name="2">携带婴儿<div class="age">14天-2岁</div></van-checkbox>
                </van-checkbox-group>
              </div>
            </div>
            <van-divider />
            <!-- 填写订票信息 -->
            <div class="ticket_info" @click="go">
              <div>订票信息</div>
              <div class="right_text">
                <span>必填</span>
                <van-icon name="arrow" />
              </div>
            </div>
            <van-divider />
            <van-button type="info" round size="large" class="button" @click="gosuccess(1)">预订</van-button>
          </div>
        </van-tab>
        <van-tab title="往返">
          <div class="one_way">
            <!-- 选择起始地址 -->
            <div class="check_address">
              <!-- <div
                :class="this.startAddress==false?'start_address none_address':'start_address'"
                v-text="this.startAddress==false?'出发地':this.startAddress"
              ></div>-->
              <input
                style="border:0;width:100px;font-size:18px"
                type="text"
                placeholder="出发地"
                maxlength="5"
                v-model="user.leave"
              />
              <div class="icon">
                <img src="@/assets/images/fj.png" alt />
              </div>
              <!-- <div
                :class="this.flagAddress==false?'flag_address none_address':'flag_address'"
                v-text="this.flagAddress==false?'目的地':this.flagAddress"
              ></div>-->
              <input
                style="border:0;width:100px;font-size:18px"
                type="text"
                placeholder="目的地"
                maxlength="5"
                v-model="user.destination"
              />
            </div>
            <van-divider />
            <!-- 选择时间 -->
            <div class="check_time">
              <div class="time_box">
                <!-- <van-cell title="往返日期" :value="date" @click="show = true" />
                <van-calendar v-model="show" type="range" @confirm="onConfirm2" />-->
                <van-field
                  readonly
                  clickable
                  name="calendar"
                  :value="user.date2"
                  label="往返日期"
                  placeholder="点击选择日期"
                  @click="show = true"
                  v-model="this.user.date2"
                  
                />
                <van-calendar color="#1989fa" v-model="show" type="range" @confirm="onConfirm2" />
              </div>
              <!-- <van-icon name="arrow" /> -->
            </div>
            <van-divider />
            <!-- 仓位以及是否携带幼儿 -->
            <div class="check_space_children">
              <div class="space">
                <van-dropdown-menu active-color="#1989fa">
                  <van-dropdown-item  v-model="value1" :options="option1" />
                </van-dropdown-menu>
              </div>
               <div class="children">
                <van-checkbox-group v-model="result" icon-size='12px' direction="horizontal">
                  <van-checkbox name="1">携带儿童<div class="age">2-12岁</div></van-checkbox>
                  <van-checkbox name="2">携带婴儿<div class="age">14天-2岁</div></van-checkbox>
                </van-checkbox-group>
              </div>
            </div>
            <van-divider />
            <!-- 填写订票信息 -->
            <div class="ticket_info" @click="go">
              <div>订票信息</div>
              <div class="right_text">
                <span>必填</span>
                <van-icon name="arrow" />
              </div>
            </div>
            <van-divider />
            <van-button type="info" round size="large" class="button" @click="gosuccess(2)">预订</van-button>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { ticket_api } from '../http/api/ticket'
import { Toast } from 'vant'
export default {
  data() {
    return {
      option1: [
        { text: '经济舱', value: 1 },
        { text: '头等/商务舱', value: 2 },
      ],
      user:{
        leave: '',
      destination: '',
       date: '',
        value2: '',
         date2: '',
        
      },
      result: [],
      // startAddress: '',
      // flagAddress: '',
      // checked1: true,
      // checked2: true,
      active: 0,
      value: '',    
      value1:1,
      showCalendar: false,
      show: false,      
      showPicker: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      i:''
    }
  },
  created(){
     let user = JSON.parse(sessionStorage.getItem('user'))
     if(user!=null){
       this.user.leave=user.leave
       this.user.destination=user.destination
       this.user.value2=user.value2
       this.user.date=user.date
       this.user.date2=user.date2
     }
     let value11 = JSON.parse(sessionStorage.getItem('value1'))
     if(value11!=null){
       this.value1=value11
     }
     let result = JSON.parse(sessionStorage.getItem('result'))
     if(result){
       console.log(result);
       this.result = result
     }
  },
  methods: {
    formatter(type, val) {
      if (type === 'month') {
        return `${val}月`
      } else if (type === 'day') {
        return `${val}日`
      }
      return val
    },
    onchange(time) {
      console.log(time);
      this.showPicker = false
      //   console.log(time)
      this.user.value2 = moment(time).format('yyyy-MM-DD')
      sessionStorage.setItem('departureTime', this.user.value2)
      console.log(this.value2)
      //console.log(this.value2);
    },
    onConfirm(date) {
      this.value = `${date.getMonth() + 1}/${date.getDate()}`
      this.showCalendar = false
    },
    //往返
    formatDate(date) {
      return `${date.getMonth() + 1}月${date.getDate()}`
    },
    onConfirm2(date) {
      console.log(date);
      const [start, end] = date
      this.show = false
      this.user.value2= `${this.formatDate(start)}`
       this.user.value2 = moment(start).format('yyyy-MM-DD')
      this.user.date = `${this.formatDate(end)}`
       this.user.date = moment(end).format('yyyy-MM-DD')

      this.user.date2=`${this.formatDate(start)}-${this.formatDate(end)}`
    },
    go() {
      sessionStorage.setItem('user',JSON.stringify(this.user) )
      sessionStorage.setItem('result',JSON.stringify(this.result) )
      sessionStorage.setItem('value1',JSON.stringify(this.value1) )
      // console.log(this.name);
      this.$router.push({
        path: '/Fjorder',
      })
    },
    async gosuccess() {
     console.log(this.i);
     this.i = 0;
     if(this.result.length==1){
        this.i=this.result[0]
     }else if(this.result.length==2){
      this.i=3;
     }
      console.log(this.i);
     
      let info = JSON.parse(sessionStorage.getItem('info'))
      if(info==null){
         Toast('请选择订票信息')
      }
     // console.log(info)
      if (info.radio == '男') {
        info.radio = 1
      } else {
        info.radio = 2
      }
      let res = await ticket_api({
        name: info.username,
        ex1: info.password,
        sex: info.radio, //性别 1:男 2:女
        leave: this.user.leave,
        destination: this.user.destination,
        leaveTime: this.user.value2, //出发时间
        returnTime: this.user.date, //返程时间
        space: this.value1, //舱位 1:经济舱 2:头等/商务舱
        childrenState: this.i, //携带儿童情况 0:不携带儿童 1:携带儿童 2:携带婴儿 3:全选
        remarks: '订票信息',
      })
      console.log(111);
      console.log(res)
      if (res.code == 200) {
        this.$router.push({
          path: '/Success',
        })
        sessionStorage.removeItem('info')
        sessionStorage.removeItem('user')
        sessionStorage.removeItem('value1')
        sessionStorage.removeItem('result')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tab {
  width: 100%;
  height: 100%;
  .head_img_box {
    width: 100%;
    height: 50%;
    .head_img {
      width: 100%;
      height: 100%;
    }
  }
  .tab_box {
    position: fixed;
    bottom: 40px;
    
    left: 5%;
    width: 90%;
    min-height: 65%;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px 3px #ccc;

    //tab未激活部分背景颜色
    /deep/.van-tabs__nav--line {
      background-color: whitesmoke;
    }
    //tab栏基线,激活文字颜色背景颜色
    /deep/.van-tabs__line {
      display: none;
    }
    /deep/.van-tab--active {
      color: #3eb1fa;
      font-weight: bold;
      border-radius: 20px 20px 0 0;
      background-color: #fff;
    }
    /deep/.van-tabs--line .van-tabs__wrap {
      border-radius: 20px 20px 0 0;
    }
    
    //单程
    /deep/.van-dropdown-menu__bar {
    
   box-shadow: 0 0 0px 0px #fff;
    }
    .one_way {
      padding: 5%;

      //选择地址
      .check_address {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .start_address,
        .flag_address {
          font-size: 20px;
          color: black;
          font-weight: bold;
        }
        .none_address {
          color: grey;
          font-weight: normal;
        }
      }
      //选择时间
      .check_time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        /deep/.van-dropdown-menu__item {
          min-width: 100px;
        }
        .time_box {
          /deep/.van-field__label {
            color: #000;
            font-size: 16px;
          }
          /deep/.van-cell::after {
            border-bottom: 0.02667rem solid #fff;
          }
          .time {
            font-size: 18px;
            margin-bottom: 5px;
          }
          .day {
            font-size: 12px;
          }
        }
      }
      //选择舱位
      .check_space_children {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;

        .children {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .van-checkbox {
            margin-left: 10px;
          }
          .son {
            font-size: 14px;
          }
          .age {
            font-size: 12px;
            color: grey;
          }
        }
      }
      //订票信息
      .ticket_info {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        .right_text {
          font-size: 14px;
        }
      }
    }
  }
}
</style>